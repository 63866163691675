import React from 'react';
import { Translate } from 'react-localize-redux';
import { Grid } from 'semantic-ui-react';
import { calculatePackagePrice, IPackageSelect, IPackageSelectProductAddon } from '../../core/services/product.service';

export interface SelectedPackageProps {
  checkinDate: string;
  isHoliday: boolean;
  selectedPackage: IPackageSelect;
  golfClubCurrency: string;
}

function SelectedPackage(props: SelectedPackageProps) {
  const { checkinDate, isHoliday, selectedPackage } = props;
  const price = calculatePackagePrice(selectedPackage, checkinDate, isHoliday);

  return selectedPackage ? (
    <Translate>
      {({ translate }) => (
        <>
          <Grid className="mb-1">
            <Grid.Row className="pt-0">
              <Grid.Column computer={8} mobile={8}>
                <div>{`${selectedPackage.name} (${selectedPackage.count}x)`}</div>
                <div>
                  {selectedPackage.nights}{' '}
                  {selectedPackage.nights > 1 ? translate('packages.nights') : translate('packages.night')}
                </div>
              </Grid.Column>
              <Grid.Column computer={7} mobile={7} style={{ whiteSpace: 'nowrap' }} textAlign="right">
                {`${price.toFixed(2).toString().replace('.', ',')} ${props.golfClubCurrency}`}
              </Grid.Column>
            </Grid.Row>
            {selectedPackage.pricings[0].productAddons.map((addon: IPackageSelectProductAddon) => (
              <Grid.Row className="pt-0 pb-0" key={Math.random().toString()}>
                <Grid.Column>{`${translate(`product-addons.type-names.${addon.name}`)} (${
                  addon.count * selectedPackage.count
                }x)`}</Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        </>
      )}
    </Translate>
  ) : (
    <></>
  );
}

export default SelectedPackage;
