import moment from 'moment';
import React from 'react';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { Button, Icon, Popup, Table } from 'semantic-ui-react';
import { IPackage } from '../../core/services/product.service';
import { PriceCategory } from './PackagesList';

export interface PackageListItemProps extends LocalizeContextProps {
  addonPackage: IPackage;
  currency?: string;
  loading?: boolean;
  priceCategories: PriceCategory[];
  onActivateClick: (addon: IPackage) => void;
  onEditClick: (addon: IPackage) => void;
  onDeleteClick: (addon: IPackage) => void;
}

function PackageListItem(props: PackageListItemProps) {
  const { currency, loading, addonPackage, onActivateClick, onEditClick, onDeleteClick, translate } = props;

  return (
    <>
      <Table.Row style={{ backgroundColor: 'transparent' }}>
        <Table.Cell>
          {addonPackage.isActivated === false && (
            <Popup
              trigger={
                <Button color="orange" size="mini" icon title={translate('packages.activate')}>
                  <Icon name="exclamation" />
                </Button>
              }
              content={
                <Button
                  color="orange"
                  content={translate('packages.confirm-activate')}
                  onClick={() => onActivateClick(addonPackage)}
                  loading={loading}
                />
              }
              on="click"
              position="top right"
            />
          )}
        </Table.Cell>
        <Table.Cell>
          {addonPackage.name}{' '}
          {addonPackage.description && <Popup trigger={<Icon name="info circle" />}>{addonPackage.description}</Popup>}
        </Table.Cell>
        <Table.Cell singleLine>
          {addonPackage.priceCategories
            .sort((a: any, b: any) => a - b)
            .map((t, index) => (index > 0 ? ', ' : '') + props.priceCategories.find(pc => pc.value === t)?.text || '')}
        </Table.Cell>
        <Table.Cell>{moment(addonPackage.validFrom).format('YYYY-MM-DD')}</Table.Cell>
        <Table.Cell>{moment(addonPackage.validTo).format('YYYY-MM-DD')}</Table.Cell>
        <Table.Cell textAlign="right">
          <Button color="green" size="mini" icon onClick={() => onEditClick(addonPackage)}>
            <Icon name="edit outline" />
          </Button>
          <Popup
            trigger={
              <Button color="red" size="mini" icon>
                <Icon name="trash alternate" />
              </Button>
            }
            content={
              <Button
                color="red"
                content={translate('default.confirm-remove')}
                onClick={() => onDeleteClick(addonPackage)}
                loading={loading}
              />
            }
            on="click"
            position="top right"
          />
        </Table.Cell>
      </Table.Row>
      <Table.Row style={{ backgroundColor: 'rgba(0,0,50,.02)' }}>
        <Table.Cell />
        <Table.Cell colSpan={2} style={{ paddingLeft: 40 }} verticalAlign="top">
          <div style={{ fontWeight: 'bold' }}>{translate('packages.headers.addons')}</div>
          {addonPackage?.pricings[0].productAddons.map(addon => (
            <div key={addon.productAddon.id}>
              {addon.count}x {translate(`product-addons.type-names.${addon.productAddon.name}`)}
            </div>
          ))}
        </Table.Cell>
        <Table.Cell colSpan={3} style={{ paddingLeft: 20 }} verticalAlign="top">
          {addonPackage.nightsCount && (
            <span style={{ width: '35%', display: 'inline-block', fontWeight: 'bold' }}>
              {translate('packages.headers.nights')}
            </span>
          )}
          <span style={{ width: '35%', display: 'inline-block', fontWeight: 'bold' }}>
            {addonPackage.chargeType === 0
              ? translate('packages.headers.nights')
              : addonPackage.chargeType === 2
              ? translate('packages.headers.checkin-day')
              : translate('packages.headers.persons')}
          </span>
          <span style={{ width: '25%', display: 'inline-block', fontWeight: 'bold', textAlign: 'center' }}>
            {translate('default.price')}
          </span>
          {addonPackage.pricings.map((pricing, index) => (
            <div key={pricing.minCount}>
              {addonPackage.nightsCount && (
                <span style={{ width: '35%', display: 'inline-block' }}>
                  {index === 0 ? addonPackage.nightsCount : ''}
                </span>
              )}
              <span style={{ width: '39%', display: 'inline-block', whiteSpace: 'nowrap' }}>
                {addonPackage.chargeType === 2
                  ? pricing.minCount === 8
                    ? translate('default.public-holidays')
                    : moment.weekdays(pricing.minCount)
                  : pricing.minCount}{' '}
                {addonPackage.chargeType === 0 ? `- ${pricing.maxCount}` : ''}
                {addonPackage.chargeType === 2 && pricing.maxCount !== 8
                  ? `- ${moment.weekdays(pricing.maxCount)}`
                  : ''}
              </span>
              <span style={{ width: '25%', textAlign: 'right', display: 'inline-block', whiteSpace: 'nowrap' }}>
                {`${pricing.price} ${currency}`}
              </span>
            </div>
          ))}
        </Table.Cell>
        <Table.Cell />
      </Table.Row>
    </>
  );
}

export default withLocalize(PackageListItem);
